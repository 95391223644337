exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-bookings-js": () => import("./../../../src/templates/bookings.js" /* webpackChunkName: "component---src-templates-bookings-js" */),
  "component---src-templates-casting-escort-job-js": () => import("./../../../src/templates/casting-escort-job.js" /* webpackChunkName: "component---src-templates-casting-escort-job-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-city-js": () => import("./../../../src/templates/city.js" /* webpackChunkName: "component---src-templates-city-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-escort-agency-js": () => import("./../../../src/templates/escort-agency.js" /* webpackChunkName: "component---src-templates-escort-agency-js" */),
  "component---src-templates-gentlemens-guide-js": () => import("./../../../src/templates/gentlemens-guide.js" /* webpackChunkName: "component---src-templates-gentlemens-guide-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-model-js": () => import("./../../../src/templates/model.js" /* webpackChunkName: "component---src-templates-model-js" */),
  "component---src-templates-models-js": () => import("./../../../src/templates/models.js" /* webpackChunkName: "component---src-templates-models-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-rates-js": () => import("./../../../src/templates/rates.js" /* webpackChunkName: "component---src-templates-rates-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-single-news-js": () => import("./../../../src/templates/single-news.js" /* webpackChunkName: "component---src-templates-single-news-js" */),
  "component---src-templates-single-tag-js": () => import("./../../../src/templates/single-tag.js" /* webpackChunkName: "component---src-templates-single-tag-js" */),
  "component---src-templates-sitemap-js": () => import("./../../../src/templates/sitemap.js" /* webpackChunkName: "component---src-templates-sitemap-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-terms-conditions-js": () => import("./../../../src/templates/terms-conditions.js" /* webpackChunkName: "component---src-templates-terms-conditions-js" */),
  "component---src-templates-vip-dashboard-js": () => import("./../../../src/templates/vip-dashboard.js" /* webpackChunkName: "component---src-templates-vip-dashboard-js" */),
  "component---src-templates-vip-login-js": () => import("./../../../src/templates/vip-login.js" /* webpackChunkName: "component---src-templates-vip-login-js" */)
}

